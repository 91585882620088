import { Link } from 'gatsby'
import * as React from 'react'
import { Container } from 'react-bootstrap'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'

const NotFoundPage = () => (
	<Layout>
		<Seo title="Not found" />
		<Container>
			<div className='d-flex' style={{ height: "80vh" }}>

				<div className='my-auto'>
					<h1>Oh no!</h1>
					<h3 >We can't seem to find the page you're looking for.</h3>
					<p>
						You might want to checkout <Link to="/">home</Link>, <Link to="/blogs">blogs </Link> or <Link to="/products">explore</Link> the latest products.
					</p>
				</div>
			</div>
		</Container>

	</Layout>
)

export default NotFoundPage
